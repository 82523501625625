import styled from 'styled-components'
import scssVars from '../../scss-variables'
import { Field as FormikField } from 'formik'
import HorizontalRule from '../../LayoutComponents/HorizontalRule'

export const Error = styled.div`
  color: ${scssVars.orange};
  font-size: 14px;
  position: relative;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  min-width: 200px;

  span {
    padding-left: 5px;
  }

  * {
    display: inline-block;
  }
`

export const BlockError = styled.div`
  color: ${scssVars.orange};
  text-align: center;
  font-size: 18px;
  padding: 20px;
  border: 1px solid ${scssVars.orange};
  margin-top: 20px;
`

export const Hr = styled(HorizontalRule)`
  width: 100%;
  border-color: ${scssVars.brown} !important;
  margin: 2rem 0;
`

export const SectionTitle = styled.h2`
  font-size: 24px;
  line-height: 34px;
  color: ${scssVars.darkBrown};
  font-weight: 700;
  position: relative;
`

export const Controls = styled.div`
  max-width: 400px;
  margin-top: 2rem;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    && {
      width: 100%;
      max-width: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const Button = styled.button`
  margin-top: 1rem;
`

export const Label = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  letter-spacing: 0.35px;
  line-height: 16.8px;
  color: ${scssVars.darkBrown};
  position: relative;
  left: 5px;
  bottom: 5px;
  margin-top: ${props => props.marginTop || '5px !important'};
  margin-bottom: 0 !important;
`

export const Field = styled(FormikField)`
  border: 0 !important;
  outline: 0 !important;
  border-bottom: 1px solid ${scssVars.beige} !important;
  box-shadow: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: ${scssVars.darkBrown} !important;
  border-radius: 0;

  &::placeholder {
    color: ${scssVars.darkBrown} !important;
  }
`

export const Input = styled.input`
  border: 0 !important;
  outline: 0 !important;
  border-bottom: 1px solid ${scssVars.beige} !important;
  box-shadow: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: ${scssVars.darkBrown} !important;
  border-radius: 0;
  margin-bottom: 20px;

  &::placeholder {
    color: ${scssVars.darkBrown} !important;
  }
`

export const TextArea = styled(Field)`
  font-family: 'Montserrat', sans-serif;
  border-bottom: 0 !important;
  width: 100%;
  height: 130px;
  background-color: #F7F6F4;
  padding: 1rem;
  resize: none;
`

export const Dropdown = styled(Field)`
  border: 1px solid transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border-bottom: 1px solid ${scssVars.beige} !important;
  margin-bottom: 10px;
  color: ${scssVars.darkBrown} !important;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>") no-repeat 100% 85%;
  -webkit-appearance: none;
  -moz-appearance: none;

  :-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &:focus {
    outline: none;
  }

  option {
    outline: none;
    position: absolute !important;
  }
`

export const CheckBox = styled(FormikField)`
  outline: none;
  height: 12px;
  -webkit-appearance: none;
  border: 1px solid #BCB5A4;
  margin: 1px;
  box-shadow: 00 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;

  &:active, &:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
  }

  &:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 00 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
    color: #99a1a7;
  }

  &:checked::after {
    line-height: 40px;
    content: '✔\fe0e';
    font-size: 28px;
    top: -12px;
    position: absolute;
    color: ${scssVars.brightOrange};
  }
`

export const Radio = styled(FormikField)`
  outline: 0;
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
  padding: 7px !important;
  height: 12px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  margin: 3px 0px 6px 0;

  &:checked:after {
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 50px;
    position: absolute;
    top: 2px;
    background-color: ${scssVars.brightOrange};
    text-shadow: 0px;
    left: 2px;
    font-size: 32px;
  }

  &:checked {
    background-color: #e9ecee;
    color: ${scssVars.brightOrange};
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
  }

  &:active, &:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
  }
`
