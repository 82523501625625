import { useStaticQuery, graphql } from 'gatsby'

export const getPostalCodes = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            postalCodes {
              id
              fees
            }
          }
        }
      }
    `
  )

  return site.siteMetadata.postalCodes
}
