import styled from 'styled-components'

import scssVars from '~components/scss-variables'

import { SectionTitle } from '../../Services/Payment/Components'

export const InlineError = styled.span`
  display: block;
  color: ${scssVars.brightOrange};
  position: relative;
  left: 10px;
`

export const BlockError = styled.div`
  color: ${scssVars.orange};
  text-align: center;
  font-size: 18px;
  padding: 20px;
  border: 1px solid ${scssVars.orange};
  max-width: 95%;
  width: 800px;
  margin: 20px auto;
`

export const Error = styled.div`
  color: ${scssVars.orange};
  text-align: center;
  font-size: 18px;
  padding: 20px;
  border: 1px solid ${scssVars.orange};
`

export const Main = styled.div`
  max-width: 1280px;
  width: 95%;
  margin: 0 auto;
  border: 2px solid ${scssVars.orange};
  padding: 42px 0;

  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    flex-direction: row;

    > * {
      width: 50%;
      border-right: 1px solid ${scssVars.gray};
    }
  }

  section {
    padding: 0 42px;

    ${SectionTitle} {
      margin-bottom: 10px;
    }

    @media only screen and (max-width: ${scssVars.mobileMax}) {
      padding: 0 10px;
    }
  }
`

export const Hr = styled.hr`
  display: none;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    display: block;
    height: 1px;
    margin: 25px 42px;
    background-color: ${scssVars.brown};
  }

  @media only screen and (max-width: ${scssVars.mobileMax}) {
    margin: 25px 10px;
  }
`

export const Button = styled.button`
  && {
    display: block;
    margin: 60px auto;
    width: 450px;
    max-width: 95%;

    svg {
      margin-right: 10px;
    }
  }
`

export const Title = styled.h1`
color: ${scssVars.darkBrown};
font-weight: 300;
padding: 50px 0 50px 0;
max-width: 1280px;
margin: 0 auto;
display: flex;

@media only screen and (max-width: ${scssVars.tabletMax}) {
  text-align: center;
  flex-direction: column;
  padding: 20px 0 20px 0;
}

span {
  @media only screen and (max-width: ${scssVars.tabletMax}) {
    padding-right: 0;
    max-width: 90%;
    margin: 0 auto;
  }
}

hr {
  background-color: ${scssVars.darkBrown};
  height: 1px;
  flex-grow: 1;
  position: relative;
  top: 7px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    top: 0;
    width: 40%;
    margin: 20px auto;
  }
}
`
